import React, { useState, useEffect } from "react";
import Slider from "react-slick"; // Import Slick Carousel
import { FaStar } from "react-icons/fa"; // For star ratings
import { useLoadScript } from "@react-google-maps/api";
import "./ReviewsCarousel.css"; // Import styles

const ReviewsCarousel = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null); // Modal state

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_RBT_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const placeId = "ChIJj4veledhhYARzR63xm5MzMY";

  useEffect(() => {
    if (!isLoaded || !placeId) return;

    const fetchReviews = async () => {
      try {
        setLoading(true);

        const maps = window.google.maps;
        const service = new maps.places.PlacesService(
          document.createElement("div")
        );
        const request = {
          placeId: placeId,
          fields: ["review"],
        };

        service.getDetails(request, (place, status) => {
          if (status === maps.places.PlacesServiceStatus.OK) {
            setReviews(place.reviews || []); // Store reviews in state
          } else {
            console.error("Failed to fetch reviews:", status);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [isLoaded, placeId]);

  const settings = {
    infinite: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0px", // No gaps in alignment
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 778,
        settings: {
          slidesToShow: 1, // One card per slide for smaller screens
          centerMode: false, // Disable center mode to avoid padding issues
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          dots: false, // Hide dots on very small screens
          arrows: false, // Hide arrows for small screens
          centerMode: false, // Ensure full-width cards
        },
      },
    ],
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar key={index} color={index < rating ? "#ffb400" : "#ddd"} />
    ));
  };

  const openModal = (review) => {
    setModalData(review);
    document.body.style.overflow = "hidden"; // Disable scroll when modal opens
  };

  const closeModal = () => {
    setModalData(null);
    document.body.style.overflow = "auto"; // Re-enable scroll
  };

  return (
    <div className="reviews-carousel">
      {loading ? (
        <p>Loading reviews...</p>
      ) : loadError ? (
        <p>Error loading Google Maps API</p>
      ) : reviews.length > 0 ? (
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="carousel-item">
              <div className="review-card">
                <div className="review-header">
                  <img
                    src={review.profile_photo_url || "/default-avatar.png"}
                    alt={review.author_name}
                    className="review-author-image"
                  />
                  <div className="review-author-details">
                    <p>
                      <strong>{review.author_name}</strong>
                    </p>
                    <div className="rating">{renderStars(review.rating)}</div>
                  </div>
                </div>
                <div className="card-text">
                  <p>
                    {review.text.length > 100 ? (
                      <>
                        {review.text.substring(0, 100)}...
                        <span
                          className="see-more"
                          onClick={() => openModal(review)}
                        >
                          {" "}
                          See More
                        </span>
                      </>
                    ) : (
                      review.text
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p>No reviews available.</p>
      )}

      {/* Modal */}
      {modalData && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <button className="close-modal" onClick={closeModal}>
              ×
            </button>
            <div className="modal-header">
              <img
                src={modalData.profile_photo_url || "/default-avatar.png"}
                alt={modalData.author_name}
                className="modal-author-image"
              />
              <div className="modal-author-details">
                <p>
                  <strong>{modalData.author_name}</strong>
                </p>
                <div className="rating">{renderStars(modalData.rating)}</div>
              </div>
            </div>
            <div className="modal-body">
              <p>{modalData.text}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsCarousel;
