import { images } from "../../assets/images";
import logo from '../../assets/logo.png'

export const markers = [
  {
    id: 1,
    name: "Home By Tom",
    sliderImageOne: images.bath1_1,
    sliderImageTwo: images.bath1_2,
    description: "Bathroom Remodeling in Lafayette, CA.",
    link: "https://www.homebytom.com/gallery/bathroom-remodeling/",
    address: "Lafayette, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 1,
    position: { lat: 37.890800, lng: -122.127472 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 2,
    name: "Home By Tom",
    sliderImageOne: images.bath2_1,
    sliderImageTwo: images.bath2_2,
    description: "Bathroom Remodeling in Moraga, CA.",
    link: "https://www.homebytom.com/gallery/bathroom-remodeling/",
    address: "Moraga, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 2,
    position: { lat: 37.838531, lng: -122.126083 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 3,
    name: "Home By Tom",
    sliderImageOne: images.bath3_1,
    sliderImageTwo: images.bath3_2,
    description: "Bathroom Remodeling in Berkeley, CA.",
    link: "https://www.homebytom.com/gallery/bathroom-remodeling/",
    address: "Berkeley, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 3,
    position: { lat: 37.869061, lng: -122.270462 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 4,
    name: "Home By Tom",
    sliderImageOne: images.complete1_1,
    sliderImageTwo: images.complete1_2,
    description: "Full Home Remodeling in Orinda, CA.",
    link: "https://www.homebytom.com/gallery/general-remodeling/",
    address: "Orinda, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 4,
    position: { lat: 37.8771476, lng: -122.1796888 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 5,
    name: "Home By Tom",
    sliderImageOne: images.deck1_1_dv,
    sliderImageTwo: images.deck1_2_dv,
    description: "Deck Construction in Danville, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Danville, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 5,
    position: { lat: 37.8225892, lng: -122.0008254 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 6,
    name: "Home By Tom",
    sliderImageOne: images.deck1_1_ph,
    sliderImageTwo: images.deck1_2_ph,
    description: "Deck Construction in Pleasant Hill, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Pleasant Hill, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 6,
    position: { lat: 37.9479786, lng: -122.0607963 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 7,
    name: "Home By Tom",
    sliderImageOne: images.kitchen1_1,
    sliderImageTwo: images.kitchen1_2,
    description: "Kitchen Remodeling in Lafayette, CA.",
    link: "https://www.homebytom.com/gallery/kitchen-remodeling/",
    address: "Lafayette, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 7,
    position: { lat: 37.881800, lng: -122.126472 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 8,
    name: "Home By Tom",
    sliderImageOne: images.kitchen2_1,
    sliderImageTwo: images.kitchen2_2,
    description: "Kitchen Remodeling in Danville, CA.",
    link: "https://www.homebytom.com/gallery/kitchen-remodeling/",
    address: "Danville, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 8,
    position: { lat: 37.8235892, lng: -122.0108254 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 9,
    name: "Home By Tom",
    sliderImageOne: images.kitchen3_1,
    sliderImageTwo: images.kitchen3_2,
    description: "Kitchen Remodeling in Berkeley, CA.",
    link: "https://www.homebytom.com/gallery/kitchen-remodeling/",
    address: "Berkeley, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 9,
   position: { lat: 37.8679061, lng: -122.280462 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 10,
    name: "Home By Tom",
    sliderImageOne: images.new_const1_1,
    sliderImageTwo: images.new_const1_2,
    description: "New Construction in Union City, CA.",
    link: "https://www.homebytom.com/gallery/general-remodeling/",
    address: "Berkeley, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 10,
   position: { lat: 37.5872576, lng: -122.0215737 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 11,
    name: "Home By Tom",
    sliderImageOne: images.outdoor1_1,
    sliderImageTwo: images.outdoor1_2,
    description: "Outdoor Remodeling in Walnut Creek, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Walnut Creek, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 11,
   position: { lat: 37.901451110839844, lng: -122.06177520751953 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 12,
    name: "Home By Tom",
    sliderImageOne: images.outdoor2_1,
    sliderImageTwo: images.outdoor2_2,
    description: "Outdoor Remodeling in Concord, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Concord, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 12,
   position: { lat: 37.98088836669922, lng: -122.02526092529297 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 13,
    name: "Home By Tom",
    sliderImageOne: images.outdoor3_1,
    sliderImageTwo: images.outdoor3_2,
    description: "Outdoor Remodeling in Oakland, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Oakland, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 13,
   position: { lat: 37.8044557, lng: -122.271356 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 14,
    name: "Home By Tom",
    sliderImageOne: images.outdoor4_1,
    sliderImageTwo: images.outdoor4_2,
    description: "Outdoor Remodeling in Vallejo, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "Vallejo, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 14,
   position: { lat: 38.102352142333984, lng: -122.25994873046875 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },
  {
    id: 15,
    name: "Home By Tom",
    sliderImageOne: images.outdoor5_1,
    sliderImageTwo: images.outdoor5_2,
    description: "Outdoor Remodeling in San Leandro, CA.",
    link: "https://www.homebytom.com/gallery/outdoor-remodeling/",
    address: "San Leandro, CA",
    placeId: "ChIJZ7EjWThZFU8Ra_lW_y3k0X4",
    reviewId: 15,
   position: { lat: 37.7249296, lng: -122.1560768 },
    contractorName: "Home By Tom.",
    contractorPicture: logo,
    phoneNumber: "(925) 430-7933",
  },


];