import bath1_1 from './bath-1/bath-1-1.jpg'
import bath1_2 from './bath-1/bath-1-2.jpg'
import bath2_1 from './bath-2/bath-2-1.jpg'
import bath2_2 from './bath-2/bath-2-2.jpg'
import bath3_1 from './bath-3/bath-3-1.jpg'
import bath3_2 from './bath-3/bath-3-2.jpg'
import complete1_1 from './complete-1/complete-1-1.jpg'
import complete1_2 from './complete-1/complete-1-2.jpg'
import deck1_1_dv from './deck-dv/deck-2.jpg'
import deck1_2_dv from './deck-dv/deck-4.jpg'
import deck1_1_ph from './deck-ph/deck-1.jpg'
import deck1_2_ph from './deck-ph/deck-10.jpg'
import kitchen1_1 from './kitchen-1/kitchen-1.jpg'
import kitchen1_2 from './kitchen-1/kitchen-2.jpg'
import kitchen2_1 from './kitchen-2/kitchen-2-2.jpg'
import kitchen2_2 from './kitchen-2/kitchen-2-3.jpg'
import kitchen3_1 from './kitchen-3/kitchen-3-1.jpg'
import kitchen3_2 from './kitchen-3/kitchen-3-3.jpg'
import new_const1_1 from './new-construction-1/new-construction-1-13.jpg'
import new_const1_2 from './new-construction-1/new-construction-1-29.jpg'
import outdoor1_1 from './outdoor-1/outdoor-1-1.jpg'
import outdoor1_2 from './outdoor-1/outdoor-1-4.jpg'
import outdoor2_1 from './outdoor-2/outdoor-2-1.jpg'
import outdoor2_2 from './outdoor-2/outdoor-2-9.jpg'
import outdoor3_1 from './outdoor-3/outdoor-3-1.jpg'
import outdoor3_2 from './outdoor-3/outdoor-3-11.jpg'
import outdoor4_1 from './outdoor-4/outdoor-4-5.jpg'
import outdoor4_2 from './outdoor-4/outdoor-4-7.jpg'
import outdoor5_1 from './ourdoor-5/ourdoor-5-2.jpg'
import outdoor5_2 from './ourdoor-5/SanLeandro.jpeg'




export const images = {
    bath1_1,
    bath1_2,
    bath2_1,
    bath2_2,
    bath3_1,
    bath3_2,
    complete1_1,
    complete1_2,
    deck1_1_dv,
    deck1_2_dv,
    deck1_1_ph,
    deck1_2_ph,
    kitchen1_1,
    kitchen1_2,
    kitchen2_1,
    kitchen2_2,
    kitchen3_1,
    kitchen3_2,
    new_const1_1,
    new_const1_2,
    outdoor1_1,
    outdoor1_2,
    outdoor2_1,
    outdoor2_2,
    outdoor3_1,
    outdoor3_2,
    outdoor4_1,
    outdoor4_2,
    outdoor5_1,
    outdoor5_2
}