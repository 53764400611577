import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import Home from './Pages/Home/Home';
import RomanoGoogleMap from './Pages/Romano/Google Map/Map/Map';
import RomanoReviews from './Pages/Romano/Reviews Carousel/ReviewsCarousel';
import SlidingGoogleMap from './Pages/SlidingDoors/Google Map/Map/Map';
import SlidingReviews from './Pages/SlidingDoors/Reviews Carousel/ReviewsCarousel';
import RoofByTomMap from './Pages/RoofByTom/Google Map/Map/Map';
import RoofByTomReviews from './Pages/RoofByTom/Reviews Carousel/ReviewsCarousel'
import HomeByTomMap from './Pages/HomeByTom/Google Map/Map/Map';
import HomeByTomReviews from './Pages/HomeByTom/Reviews Carousel/ReviewsCarousel';
import TopHomeRoofers from './Pages/TopRoof/Google Map/Map/Map';
import TopHomeBuilders from './Pages/TopHome/Google Map/Map/Map';
import GladiatorReviews from './Pages/Gladiator/Reviews Carousel/ReviewsCarousel'

const router = createBrowserRouter([
  {path: '/', element: <Home />, errorElement: <NotFoundPage /> },
  {path: '/romanomap', element: <RomanoGoogleMap />},
  {path: '/romanoreviews', element: <RomanoReviews />},
  {path: '/slidingmap', element: <SlidingGoogleMap />},
  {path: '/slidingreviews', element: <SlidingReviews />},
  {path: '/rbtmap', element: <RoofByTomMap />},
  {path: '/rbtreviews', element: <RoofByTomReviews />},
  {path: '/hbtmap', element: <HomeByTomMap />},
  {path: '/hbtreviews', element: <HomeByTomReviews />},
  {path: '/tophomeroofersmap', element: <TopHomeRoofers />},
  {path: '/tophomebuildersmap', element: <TopHomeBuilders />},
  {path: '/gladiatorreviews', element: <GladiatorReviews />},
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);


